import React ,{useState,useEffect} from "react";
import "./feedback.scss";
import { Tabs, Tab } from "react-bootstrap";
import FeedbackContent from "../FeedbackContent";
import { createFeedback } from "../../services/api";
import { useMutation } from "@tanstack/react-query";
import Spinner from "react-bootstrap/Spinner";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { useTracking } from "react-tracking";
const FeedbackModal = () => {
  const  {t} = useTranslation()
  const {trackEvent} = useTracking()
  const [defaultTab,setDefaultTab]=useState("Advice")
  const [notification, setNotification] = useState({
    open: false,
    variant: "",
    message: "",
  });
  const setNotifications = () => {
    setNotification({ openn: false, variant: "", message: "" });
  };
  const addFeedback=useMutation(createFeedback)
  const createFeedbackcall=(feedback,type)=>{
    addFeedback.mutate(
      {
        "feedback": feedback,
        "type":type // Advise, Process, Analytics
      },
      {
        onSuccess:()=>{
          trackEvent({
            event_type: "request", 
            event_name:"User Submitted Feedback", 
            event_data:{
              feedback: `${feedback}`,
              module: `${type}`
            }
          })
          setNotification({
            open: true,
            variant: "success",
            message: `${t("feedback_submitted")}`,
          });

        }
      },
       {
        onError:()=>{
          trackEvent({
            event_type: "error", 
            event_name:"Failed to submit user feedback", 
            event_data:{
              feedback: `${feedback}`,
              module: `${type}`
            }
          })                    
        }
      }
    )

  }
  useEffect(()=>{
    if (window.location.pathname.includes("invoice")) {
      setDefaultTab('Process')
    } 
    else  if (window.location.pathname.includes("analyse")) {
      setDefaultTab('Analyze')
    }
    else {
      setDefaultTab('Advice')
    }
  },[window.location.pathname])
  const handleTabSelect=(selectedTab)=>{
    setDefaultTab(selectedTab)
  }
  return (
    <>
      <div className="feedbackModalWrapper"
           style={{
            direction: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "rtl" : "ltr"}`,
            position: "fixed",
            left:  `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "30px" : ""}`,
            right: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "" : "30px"}`
          }}
      >
      {addFeedback?.isLoading && (
        <div className="overlay">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{t("loading")}</span>
          </Spinner>
        </div>
      )}
        <div className="feedbackHeader">{t("feedback_box")}</div>
        <div className="feedbackBody">
          <Tabs defaultActiveKey={defaultTab} id="uncontrolled-tab-example"
                        activeKey={defaultTab}
                        onSelect={handleTabSelect}
                        >
            <Tab eventKey="Advice" title={`${t("advise")}`}>
              <FeedbackContent type={'Advise'} call={createFeedbackcall} />
            </Tab>
            {/* <Tab eventKey="Process" title={`${t("process")}`}>
            <FeedbackContent type={'Process'} call={createFeedbackcall}/>
            </Tab>
            <Tab eventKey="Analyze" title={`${t("analyse")}`}>
            <FeedbackContent type={'Analyse'} call={createFeedbackcall}/>
            </Tab> */}
          </Tabs>
        </div>
        {notification.open && (
        <Notification
          open={notification.open}
          variant={notification.variant}
          message={notification.message}
          setNotifications={setNotifications}
        />
      )}
      </div>
    </>
  );
};
export default FeedbackModal;
