
export const Environments = {
  "development": "http://127.0.0.1:8000/api/v1",
  "qa": "http://13.51.98.205:8000/api/v1",
  "dev": "https://sbx.simpla.ai:8000/api/v1",
  "prod": "https://app.simpla.ai:8000/api/v1",
  "gt": `https://gt.simpla.ai:8000/api/v1`,
}

const env_value = process.env.REACT_APP_PROJECT_MODE; 

function getBoolean(envVar, defaultValue = false) {
  if (envVar?.toLowerCase() === 'true') return true;
  if (envVar?.toLowerCase() === 'false') return false;
  return defaultValue;
}

export const isPromptPlus = getBoolean(process.env.REACT_APP_PROMPT_PLUS_FEATURE);

// if (env_value === "development"){
//   console.log("Development mode")
// }
// else{
//   console.log("comparison failed")
// }

// console.log("getCurrentEnvironment() = ",getCurrentEnvironment())
// console.log("REACT_APP_project_mode", process.env.REACT_APP_project_mode
//console.log("env_value:", env_value, Environments[`${env_value}`])

// if (!env_value){
  
// }

export const SERVICES = {
  apiBaseUrl: Environments[env_value],
};
