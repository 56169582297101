import React, { useState, useEffect } from "react";
import { ModalCloseIcon } from "../../assets/SVG/svg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./history.scss";
import AIChat from "../AiChat";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import {
  editBatch,
  deleteBatch,
  editChatroom,
  deleteChatroom,
  editAnalyticsHistory,
  deleteAnalyticsHistory,
} from "../../services/api";
import { useTracking } from "react-tracking";
import { useTranslation } from "react-i18next";

const HistoryModal = (props) => {
  const { trackEvent } = useTracking()
  const { t } = useTranslation()
  const [modalflag, setModalflag] = useState(true);
  const [activeTab, setActiveTab] = useState(
    window.location.pathname.includes("invoice") ? "UploadInvoice" : "AiChat"
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (window.location.pathname.includes("invoice")) {
      setActiveTab("UploadInvoice");
    } else if (window.location.pathname.includes("analyse")) {
      setActiveTab("analyze");
    } else {
      setActiveTab("AiChat");
    }
  }, [window.location.pathname]);
  const handleTabSelect = (tab) => {
    if (tab === "UploadInvoice") {
      if (!window.location.href.includes("invoice")) {
        trackEvent({ event_type: "click", event_name: "Module Switched", event_data: { action: "module was changed", module_selected: "Process" } })
      }
      navigate("/invoice")
    } else if (tab === "analyze") {
      if (!window.location.href.includes("analyse")) {
        trackEvent({ event_type: "click", event_name: "Module Switched", event_data: { action: "module was changed", module_selected: "Analyse" } })
      } navigate("/analyse");
    } else if (tab === "AiChat") {
      trackEvent({ event_type: "click", event_name: "Module Switched", event_data: { action: "module was changed", module_selected: "Advise" } })
      navigate("/");
    }
  };

  return (
    <>
      {modalflag ? (
        <div className={`historyModal ${localStorage.getItem("i18nextLng") === "ar" ? "historyModal-ar" : "historyModal-en"}`}>

          {props?.loading && (
            <div className="overlay-history">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">{t("loading")}</span>
              </Spinner>
            </div>
          )}
          <div className="historyModal-container">
            <div className="historyModal-header">
              <span>{`${t("history")}`}</span>


              <span
                className="closeHistory"
                onClick={() => {
                  props?.onClose();
                }}
              >
                <ModalCloseIcon />
              </span>
            </div>
            <Tabs
              defaultActiveKey={activeTab}
              id="uncontrolled-tab-example"
              activeKey={activeTab}
              onSelect={handleTabSelect}
            >
              <Tab eventKey="AiChat" title={`${t("advise")}`}>
                <div className="form-control-icon-wrapper mb-3">
                  <input
                    className="form-control form-control-custom"
                    type="text"
                    placeholder={`${t("search")}`}
                    value={props?.search}
                    onChange={(e) => { props?.searchSetter(e.target.value); }}
                  />
                </div>
                <AIChat
                  data={props?.data}
                  activeTab={activeTab}
                  refetch={props?.refetch}
                  editApi={editChatroom}
                  dellApi={deleteChatroom}
                  text={`${t("chats")}`}
                  filterSetter={props?.filterSetter}
                  filter={props?.filter}
                />
              </Tab>

              {/* Process and Analysis Tabs */}
              {/* <Tab eventKey="UploadInvoice" title={`${t("process")}`}>
                <div className="form-control-icon-wrapper mb-3">
                  <input
                    className="form-control form-control-custom"
                    type="text"
                    placeholder={`${t("search")}`}
                    value={props?.search}
                    onChange={(e) => props?.searchSetter(e.target.value)}
                  />
                </div>
                <AIChat
                  data={props?.data}
                  activeTab={activeTab}
                  refetch={props?.refetch}
                  editApi={editBatch}
                  dellApi={deleteBatch}
                  text={`${t("uploads")}`}
                  filterSetter={props?.filterSetter}
                  filter={props?.filter}
                />
              </Tab>
              <Tab eventKey="analyze" title={`${t("analyse")}`}>
                <div className="form-control-icon-wrapper mb-3">
                  <input
                    className="form-control form-control-custom"
                    type="text"
                    placeholder={`${t("search")}`}
                    value={props?.search}
                    onChange={(e) => props?.searchSetter(e.target.value)}
                  />
                </div>
                <AIChat
                  data={props?.data}
                  activeTab={activeTab}
                  refetch={props?.refetch}
                  editApi={editAnalyticsHistory}
                  dellApi={deleteAnalyticsHistory}
                  text={`${t("analysis")}`}
                  filterSetter={props?.filterSetter}
                  filter={props?.filter}
                />
              </Tab> */}
            </Tabs>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default HistoryModal;
