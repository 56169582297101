import React, { useEffect, useState } from "react";
import Joyride from "react-joyride";
import { useTour } from "./TourContext";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import "./joyride.scss";
import { UseDispatch, useDispatch } from "react-redux";
import { setDisplaySidebar } from "../../../ReduxSlices/SidebarSlice";
import { useTracking } from "react-tracking";
import { useTranslation } from "react-i18next";
const JoyrideComponent = () => {
  const dispatch = useDispatch();

  const {trackEvent} = useTracking()
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Adds a smooth scrolling effect
    });
  }

  // useEffect(()=>{
  //       if(tourSteps==4)
  // },[tourSteps])

  const [currentStep, setCurentStep] = useState({
    index: 0,
    feature_name: "",
  })  

  const navigate = useNavigate();
  const { tourState, nextStep, endTour, prevStep,setTourJump } = useTour();
  const {t} = useTranslation()
  const headerJoyride = (
    <div className="joyride_header">
      <label className="form-label"> {t("joyride_guide_me")} </label>
      <Dropdown>
        <Dropdown.Toggle id="dropdown-basic" className="bg-transparent border-0">
        {t("joyride_select_feature")}
        </Dropdown.Toggle>
  
        <Dropdown.Menu>
          <Dropdown.Item onClick={()=>{
            navigate('/')
            setTourJump(0)
            setCurentStep({...currentStep, index: 0, feature_name: "Advise"})
            }}>{t("advise")}</Dropdown.Item>
          <Dropdown.Item onClick={()=>{            navigate('/')
             setCurentStep({...currentStep, index: 1, feature_name: "How to create a new chat"})
             setTourJump(1)}}>{t("joyride_create_newchat")}</Dropdown.Item>
          <Dropdown.Item onClick={()=>{            navigate('/')            
          setCurentStep({...currentStep, index: 0, feature_name: "History"})
          setTourJump(2)}}>History</Dropdown.Item>
         
                      <Dropdown.Item onClick={()=>{ navigate('/')
            setTourJump(3)}}>Account Settings</Dropdown.Item>
          <Dropdown.Item onClick={()=>{
            navigate('/')             
            setCurentStep({...currentStep, index: 4, feature_name: "Feedback"})
            setTourJump(4)}}>Feedback</Dropdown.Item>
          <Dropdown.Item onClick={()=>{
            navigate('/')
            setTourJump(5)}}>Help-link to revisit to tutorial</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );

  function feedbackBtnPlacement(){
    let alignment = "";

    if (window.innerWidth > 450 && window.innerWidth <= 750 && localStorage.getItem('i18nextLng')?.toString() === "ar") {
      alignment = "center";
    } 
    else if (window.innerWidth > 450 && window.innerWidth <= 750){
      alignment = "center"
    }
    else if (window.innerWidth < 450 && localStorage.getItem('i18nextLng')?.toString() === "ar") {
      alignment = "right";
    } 
    else if (window.innerWidth < 450){
      alignment = "left"
    }
    else if (window.innerWidth > 750 && localStorage.getItem('i18nextLng')?.toString() === "ar") {
        alignment = "left";
      } 
    else {
        alignment = "right";
      }

      return alignment;
    }
  const tourSteps = [
    {
      target: ".advise-module-target",
      content: (
        <div >
          {headerJoyride}
          <p className="joyride_body">
            {t("joyride_advise")}
          </p>
          <div className="tooltip_counting">Tip:1/6</div>
        </div>
      ),
      disableBeacon: true,

    },
    {
      target: `${window.innerWidth < 750 ? ".add-btn-mobile" : ".add-btn-desktop"}`,
      content: (
        <div>
          {headerJoyride}
          <p className="joyride_body">
          {t("joyride_new_chat")}
          </p>
          <div className="tooltip_counting">Tip:2/6</div>
        </div>
      ),
      placement:`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "left" : "right"}`
    },
    {
      target: window.innerWidth < 750 ? ".btn-history-mobile" : ".btn-history-desktop",
      content: (
        <div>
          {headerJoyride}
          <p className="joyride_body">
            {t("joyride_history")}
          </p>
          <div className="tooltip_counting">Tip:3/6</div>
        </div>
      ),
      page: "/",
      placement:`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "left" : "right"}`
    },
    // {
    //   target: ".Tab-control-wrapper",
    //   content: (
    //     <div>
    //       {headerJoyride}
    //       <p className="joyride_body">
    //       {t("joyride_module_toggle")}
    //       </p>
    //       <div className="tooltip_counting">Tip:4/7</div>
    //     </div>
    //   ),
    //   page: "/",
    // },


    {
      target: window.innerWidth < 750 ? ".profile-dropdown-mobile" : ".profile-dropdown",
      content: (
        <div>
          {headerJoyride}
          <p className="joyride_body">
            {t("acc_setting_description")}
          </p>
          <div className="tooltip_counting">Tip:4/6</div>
        </div>
      ),
      // placement:'right'
    },
    {
      target: ".feedback-btn",
      content: (
        <div>
          {headerJoyride}
          <p className="joyride_body">
          {t("joyride_feedback")}
          </p>
          <div className="tooltip_counting">Tip:5/6</div>
        </div>
      ),
      page: "/",
      placement: `${feedbackBtnPlacement()}`
    },
    {
      target: `${window.innerWidth < 750 ? ".btn-help-mobile" : ".btn-help-desktop"}`,
      content: (
        <div >
          {headerJoyride}
          <p className="joyride_body">
          Help - consists of the tools that will assist you in navigating the app. Check out our video tutorials, FAQs and even to take on a Product tour once more. 
          </p>
          <div className="tooltip_counting">Tip:6/6</div>
        </div>
      ),
      page: "/",
      placement:`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "left" : "right"}`

    },
  ];



  const getStepTitle = (index) => {
    if (index >= 0 && index < tourSteps.length) {
      const step = tourSteps[index];
      const contentText = step.content.props.children[1].props.children;
      const titleBeforeHyphen = `${contentText}`.split(' - ')[0]?.trim();
      return titleBeforeHyphen;
    } else {
      return "N/A"; // Handle invalid index or out-of-bounds index
    }
  };

  useEffect(()=>{
    if (tourState.isTourActive && (tourState.currentStep < tourSteps.length)){
    trackEvent({event_type: "click",event_name: "Product Tour Step Changed", event_data: {index: (tourState.currentStep), feature_name: `${getStepTitle(tourState.currentStep)}`}})          
    }
  }, [tourState.currentStep])

  useEffect(() => {
    if (tourState.isTourActive && (tourState.currentStep >= tourSteps.length)) {
      // Tour has reached the end
      dispatch(setDisplaySidebar('none'))
      endTour();

      
    }

  }, [tourState.isTourActive, tourState.currentStep]);

  return (
    <Joyride
      steps={tourSteps}
      // buttonNext="custom-next-button"
      continuous={true}
      run={tourState.isTourActive}
      stepIndex={tourState.currentStep}
      showSkipButton={false}
      styles={{
        options: {
          arrowColor: "#141E14",
          padding: "16px 12px",
          backgroundColor: "#141E14",
          overlayColor: "rgba(77, 79, 92, 0.20)",
          primaryColor: "#red",
          textColor: "#FFF",
          width: 370,
          zIndex: 1000,
          marginLeft:'100px',
        },
      }}
      // showSkipButton={true}
      callback={(e) => {

        if(window.innerWidth <= 750 && (e.index === 0 || e.index === 3 || e.index === 4)){
          console.log('sidebar = none')
          dispatch(setDisplaySidebar('none'))
          scrollToTop()
        }

        else if (window.innerWidth <= 750 && (e.index === 1 || e.index === 2 || e.index === 5)){
          scrollToTop()
          dispatch(setDisplaySidebar('block'))
        }

        if(e.index===tourState.currentStep){

          

        if (e?.step?.page) {
          //console.log('index=' + e.index + "\nroute=" + e.step.page)
          // Switch the page first
          navigate(e.step.page);
        }
     
        if (e.action === "next" && e.type === "step:after") {
          console.log("next step");     
          nextStep();
         
        }
         else if (e.action === "prev" && e.type === "step:after") {
          console.log("previous step");
          prevStep();

        }}
         if (e.action === "close"||e.type==="tour:end"||e.action==='stop') {
              trackEvent({event_type: "click",event_name: "User Ended Product Tour", event_data: {action: "Tutorial was ended"}})
          endTour();  
          dispatch(setDisplaySidebar('none'))
          navigate('/');

        }
      }}
    />
  );
};
export default JoyrideComponent;
