import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { AuthProvider } from "./utils/AuthContext";

import { AuthenticatedRoutes } from "./utils/AuthenticatedRoutes";
import { UnAuthenticatedRoutes } from "./utils/UnAuthenticatedRoutes";

// import Login from "./pages/Login/Login";
// import SignUp from "./pages/SignUp/SignUp";
// import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
// import SetupAccount from "./pages/SetupAccount/SetupAccount";
// import ResetPassword from "./pages/ForgotPassword/ResetPassword";
// import ChatLive from "./components/Chat/ChatLive";
// import ChatAi from "./components/ChatAi/ChatAi";
// import InvoiceScreen from "./pages/Invoice/invoice";
import { GoogleRedirect } from "./pages/Login/GoogleRedirect";
// import Analyze from "./pages/Analysis";
// import AnalyzeData from "./pages/AnalyzeData";
import { TourProvider } from "./components/common/joyRide/TourContext";
// import DataResultsOnboarding from "./pages/DataResultsOnboarding";
// import GraphOnboardingResult from "./pages/GraphResultOnboarding";
// import Verification from "./pages/SignUp/Verification";

import { SetAxiosConfig } from "./utils/axiosConfig";

import { useSelector, useDispatch } from "react-redux";
import { setDisplaySidebar } from "./ReduxSlices/SidebarSlice";
// import { setUserIp } from "./ReduxSlices/UserIp";

import { Suspense, useEffect, useMemo, useRef, useState, lazy } from "react";

import { useTracking } from "react-tracking";
//import { deviceType, browserName, browserVersion, deviceDetect  } from 'react-device-detect';
//import { SurveyForm } from "./pages/SurveyForm/SurveyForm";
import { setProfileImage } from "./ReduxSlices/ProfileImageSlice";
import { SERVICES } from "./utils/ConstantSettings";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import ChatBotLayout from "./pages/ChatbotLayout/ChatbotLayout";
import { setSignOffCredits } from "./ReduxSlices/SignOffCreditsSlice";

//const ChatBotLayout = lazy(() => import("./pages/ChatbotLayout/ChatbotLayout"))

const SurveyForm = lazy(() => import('./pages/SurveyForm/SurveyForm'));
const StarterPlan = lazy(() => import("./pages/PaymentPlan/StarterPlan"));
const PaymentPlan = lazy(() => import("./pages/PaymentPlan/PaymentPlan"));
const PaymentSuccess = lazy(() => import("./pages/PaymentPlan/PaymentSuccess"));
const PaymentFailed = lazy(()=> import ("./pages/PaymentPlan/PaymentFailed"));

const CancelSubscription = lazy(()=> import("./pages/PaymentPlan/CancelSubscription"))
const SubscriptionCancelled = lazy(()=> import("./pages/PaymentPlan/SubscriptionCancelled"))

const Login = lazy(() => import("./pages/Login/Login"));
const SignUp = lazy(() => import("./pages/SignUp/SignUp"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword/ForgotPassword"));
const SetupAccount = lazy(() => import("./pages/SetupAccount/SetupAccount"));
const ResetPassword = lazy(() => import("./pages/ForgotPassword/ResetPassword"));
const ChatLive = lazy(() => import("./components/Chat/ChatLive"));
const ChatAi = lazy(() => import("./components/ChatAi/ChatAi"));
const InvoiceScreen = lazy(() => import("./pages/Invoice/invoice"));
// const GoogleRedirect = lazy(() => import("./pages/Login/GoogleRedirect"));
const Analyze = lazy(() => import("./pages/Analysis"));
const AnalyzeData = lazy(() => import("./pages/AnalyzeData"));
// const { TourProvider } = lazy(() => import("./components/common/joyRide/TourContext"));
const DataResultsOnboarding = lazy(() => import("./pages/DataResultsOnboarding"));
const GraphOnboardingResult = lazy(() => import("./pages/GraphResultOnboarding"));
const Verification = lazy(() => import("./pages/SignUp/Verification"));

// Functional component for Wrapper
const Wrapper = () => {
  const { name } = { name: "App" };
  const { trackEvent } = useTracking();

  // Using useEffect to mimic componentDidMount behavior for class components
  useEffect(() => {
    //trackEvent({ event_type:"reload", event_name:"Page Reload"});
  }, [ trackEvent]);

};

function App() {


  const { trackEvent } = useTracking();

  const dispatch = useDispatch()

  const getUserIp = async () => {
    try {
      const response = await fetch("https://api.ipify.org/?format=json");
      if (!response.ok) {

        trackEvent({
          event_type: "error", 
          event_name:"Failed to fetch user's IP Address", 
          event_data:{
            error: `Response Status: ${response.status}`,
            api: "https://api.ipify.org/?format=json"
          }
        })

        throw new Error(`HTTP error! Status: ${response.status}`);

      }

      const data = await response.json();
      //console.log(data);
      localStorage.setItem("user_ip", data.ip)

      // Fetch geolocation based on the obtained IP
      const geoResponse = await fetch(`https://ipapi.co/${data.ip}/json/`);
      if (!geoResponse.ok) {
        throw new Error(`Geolocation API request failed! Status: ${geoResponse.status}`);
      }

    const geoData = await geoResponse.json();
    // console.log(geoData); // contains extensive geo location data
    localStorage.setItem("user_geo_data", JSON.stringify({ country: geoData?.country_name, region: geoData?.region, city: geoData?.city, }))

    } catch (error) {
      //console.error('Error fetching data:', error.message);
      trackEvent({
        event_type: "error", 
        event_name:"Failed to fetch user's GEO Location Data", 
        event_data:{
          error: `${error}`
        }
      })
    }
  };

  
  function getSignOffCredits(){
    axios.get(`${SERVICES.apiBaseUrl}/signoff/get_credits`, 
    {headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    }})
    .then((res)=>{
      try{
        let credits = JSON.parse(res.data.message)?.signoff_credits;
        dispatch(setSignOffCredits(`${credits}`));
      }
      catch(error){
        dispatch(setSignOffCredits("0"))
      }
    })
    .catch((error)=>{dispatch(setSignOffCredits("0"))})
  }


  function fetchImageUrl(){
    axios.get(`${SERVICES.apiBaseUrl}/user/getprofileimage`, 
    {headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    }})
      .then((res)=>{
        // console.log("image key", res.data)
        // localStorage.setItem("profile_image_url", res.data?.profile_image_url)
        dispatch(setProfileImage(`${btoa(`${res.data?.profile_image_url}`)}aKb9p7`))
      })
      .catch((error)=>{
        //console.log("error occured while getting image")
      })
  }

  function fetchSimplaEnterpriseMailStatus(){
    axios.get(`${SERVICES.apiBaseUrl}/api_usage/getPremiumRequestStatus`, 
    {headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    }})
      .then((res)=>{
        let status;
        try {
          status = JSON.parse(res.data?.object)?.status
        }
        catch(error){
          trackEvent({
            event_type: "error", 
            event_name:"Failed to parse response data get-in-touch email API.", 
            event_data:{
              error: `${error}`
            }
          })
          localStorage.setItem("simpla_enterprise_email_sent", false )
        }
        localStorage.setItem("simpla_enterprise_email_sent", status )
      })
      .catch((error)=>{
        //console.log("error occured while getting image")
        trackEvent({
          event_type: "error", 
          event_name:"Failed to get status of get-in-touch email request for discover simpla enterprise.", 
          event_data:{
            error: `${error}`
          }
        })
      })
  }

// uses browser's  navigator API to get accurate location:

// const getLocation = () => {
//   if (navigator.geolocation) {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         console.log('Latitude:', latitude);
//         console.log('Longitude:', longitude);

//         // Set the obtained coordinates in the state or use them as needed
//         //setGeoData({ latitude, longitude });
//       },
//       (error) => {
//         console.error('Error getting location:', error.message);
//       }
//     );
//   } else {
//     console.error('Geolocation is not supported by this browser.');
//   }
// };


  useEffect(()=>{

    //getSignOffCredits()

    getUserIp() // gets user ip
    //getLocation() //gets accurate user location coordinates but requires user permission!
    //fetchImageUrl() // profile image feature currently omitted

    // if (localStorage.getItem('token')){
    //   fetchSimplaEnterpriseMailStatus()
    //   }
      
    if (localStorage.getItem("i18nextLng") == undefined || localStorage.getItem("i18nextLng").includes("en-")){
      localStorage.setItem("i18nextLng", "en")  
    }
    else if (localStorage.getItem("i18nextLng") != "ar"){
      localStorage.setItem("i18nextLng", "en")
    }

  }, [])

  localStorage.setItem("apiCounter", `0`)
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  // const sidebarRef = useRef(null);

  // const displayBar = useSelector((state)=>state.displaySidebar.value)

  // const handleOutsideClick = (event) => {
  //   if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
  //     dispatch(setDisplaySidebar('none'));
  //   }
  // };

  // reset sidebar view on a change in device screen width
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 600) {
        dispatch(setDisplaySidebar('none'));
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // useEffect(() => {
  //   if (displayBar === 'block') {
  //     window.addEventListener('click', handleOutsideClick);
  //   }

  //   return () => {
  //     window.removeEventListener('click', handleOutsideClick);
  //   };
  // }, [displayBar]);

  // Suspense-wrapped functions
const SuspenseFallback = (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // width: '100vw',
      height: '100vh',
      background: '#faf5e1',
      paddingBottom: '10%',
    }}
  >
    <Spinner />
  </div>
);
  const LazySurveyForm = () => (
    <Suspense
      fallback={SuspenseFallback}
    >
      <SurveyForm />
    </Suspense>
  );

  const LazyChatAi = () => (
    <Suspense
      fallback={SuspenseFallback}
    >
      <ChatAi />
    </Suspense>
  );

  const LazyLogin = () => (
    <Suspense
      fallback={SuspenseFallback}
    >
      <Login />
    </Suspense>
  );

  const LazySignUp = () => (
    <Suspense
      fallback={SuspenseFallback}
    >
      <SignUp />
    </Suspense>
  );


  const LazyInvoiceScreen = () => (
    <Suspense
      fallback={SuspenseFallback}
    >
      <InvoiceScreen />
    </Suspense>
  );

  const LazyChatLive = () => (
    <Suspense
      fallback={SuspenseFallback}
    >
      <ChatLive />
    </Suspense>
  );

  const LazyAnalyze = () => (
    <Suspense
      fallback={SuspenseFallback}
    >
      <Analyze />
    </Suspense>
  );

  const LazyAnalyzeData = () => (
    <Suspense
      fallback={SuspenseFallback}
    >
      <AnalyzeData />
    </Suspense>
  );

  const LazyGraphOnboardingResult = () => (
    <Suspense
      fallback={SuspenseFallback}
    >
      <GraphOnboardingResult />
    </Suspense>
  );

  const LazyDataResultsOnboarding = () => (
    <Suspense
      fallback={SuspenseFallback}
    >
      <DataResultsOnboarding />
    </Suspense>
  );



const LazyVerification = () => (
  <Suspense fallback={SuspenseFallback}>
    <Verification />
  </Suspense>
);

// const LazyGoogleRedirect = () => (
//   <Suspense fallback={SuspenseFallback}>
//     <GoogleRedirect />
//   </Suspense>
// );

const LazyForgotPassword = () => (
  <Suspense fallback={SuspenseFallback}>
    <ForgotPassword />
  </Suspense>
);

const LazySetupAccount = () => (
  <Suspense fallback={SuspenseFallback}>
    <SetupAccount />
  </Suspense>
);

const LazyResetPassword = () => (
  <Suspense fallback={SuspenseFallback}>
    <ResetPassword />
  </Suspense>
);

const LazyStarterPlan = () => (
  <Suspense fallback={SuspenseFallback}>
    <StarterPlan />
  </Suspense>
);

const LazyPaymentPlan = () => (
  <Suspense fallback={SuspenseFallback}>
    <PaymentPlan />
  </Suspense>
);

const LazyPaymentSuccess = () => (
  <Suspense fallback={SuspenseFallback}>
    <PaymentSuccess />
  </Suspense>
);

const LazyPaymentFailed = () => (
  <Suspense fallback={SuspenseFallback}>
    <PaymentFailed />
  </Suspense>
);

const LazyCancelSubscription = () => (
  <Suspense fallback={SuspenseFallback}>
    <CancelSubscription />
  </Suspense>
);

const LazySubscriptionCancelled = () => (
  <Suspense fallback={SuspenseFallback}>
    <SubscriptionCancelled />
  </Suspense>
);

  return (<>

    <Wrapper/>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <TourProvider>
          <Router>
            <Routes>
              <Route element={<UnAuthenticatedRoutes />}>

                <Route path="/login" element={<LazyLogin />} />
                {/* <Route path="/signup" element={<LazySignUp />} /> */}
                <Route path="/forgotpassword" element={<LazyForgotPassword />} />
                <Route path="/resetpassword" element={<LazyResetPassword />} />
                <Route path="/google-redirect" element={<GoogleRedirect />} />
                <Route path='/verify-email' element={<LazyVerification/>}  />

              </Route>

              
              <Route path="/accountsetup" element={<LazySetupAccount />} />

              {/* <Route path="/paymentplan" element={<LazyPaymentPlan />} />
              <Route path="/subscription-details" element={<LazyStarterPlan />} />
              <Route path="/paymentsuccess" element={<LazyPaymentSuccess />} />
              <Route path="/paymentfailed" element={<LazyPaymentFailed />} />
              <Route path="/cancelsubscription" element={<LazyCancelSubscription />} />
              <Route path="/subscriptioncancelled" element={<LazySubscriptionCancelled />} /> */}

              <Route element={<AuthenticatedRoutes />}>

              <Route path="/" element={<ChatBotLayout><LazyChatAi/></ChatBotLayout>}/>

              {/* <Route path="/survey" element={ <ChatBotLayout><LazySurveyForm/></ChatBotLayout> } /> */}
              




              <Route path="/chat/:id/:cid" element={<ChatBotLayout><LazyChatLive /></ChatBotLayout>} />
              <Route path="" element={<ChatBotLayout><LazyChatAi /></ChatBotLayout>} />
              {/* <Route path="/invoice" element={<ChatBotLayout><LazyInvoiceScreen /></ChatBotLayout>} />
              <Route path="/invoice/:id/:cid" element={<ChatBotLayout><LazyInvoiceScreen /></ChatBotLayout>} />
              <Route path="/analyse" element={<ChatBotLayout><LazyAnalyze /></ChatBotLayout>} />
              <Route path="/analyse/:id/:cid" element={<ChatBotLayout><LazyAnalyzeData /></ChatBotLayout>} />
              <Route path="/invoiceOnboarding" element={<ChatBotLayout><LazyDataResultsOnboarding /></ChatBotLayout>} />
              <Route path="/analyseOnboarding" element={<ChatBotLayout><LazyGraphOnboardingResult /></ChatBotLayout>} /> */}

              </Route>
            </Routes>
          </Router>
        </TourProvider>
      </AuthProvider>
    </QueryClientProvider>
  </>);
}

export default App;
